import debounce from "lodash.debounce";
import { UAParser } from "ua-parser-js";

/**
 * @type {AnalytikaEvent} - signifies the analytika event struct
 * @property {string} event_name - event_name should take format as domain_action_object string
 * @property {number} event_version - the version of the event you are sending
 */
export interface AnalytikaEvent {
  event_name: string;
  event_version: number;
  event_trigger_time?: number;
  [key: string]: unknown;
}

interface AnalytikaRequest {
  sessionAttributes: Record<string, string | number | undefined>;
  events: AnalytikaEvent[];
}

export type Environment = "prod" | "dev" | "test" | "qa";

const persistedQueueName = "queueLog";

/**
 * Represents analytika singleton instance for sending events.
 * @class
 */
export class Analytika {
  // batchTime is batch in seconds
  private readonly _batchTime: number = 30;

  // maximum queue size to start flushing
  private readonly _maxBatchSize: number = 30;

  // in msec them maximum wait time from sending event to send batch 1 minute
  private readonly _maxWaitBeforeSending: number = 60 * 1000;

  // web platform schema version
  private readonly _platform_schema_version = "web_v1";

  private readonly qaInClusterEndpoint =
    "https://analytika-v2-http.dev.careem-rh.com/v3/publish-event";

  private readonly prodInClusterEndpoint =
    "https://analytika-v2-http.prod.careem-rh.com/v3/publish-event";

  // inaccessible variables for endpoints
  private readonly prodEndpoint =
    "https://platform.careem.com/events/v3/publish-event";

  private readonly qaEndpoint =
    "https://platform.sandbox.careem.com/events/v3/publish-event";

  // current environment for sdk
  private readonly _env: Environment = "prod";

  // local storage name in queue
  private readonly _storageQueueName: string;

  // event expiration in days of closing
  private readonly _expirationInDays: number = 3;

  // backend url
  private readonly _url: string;

  // object for triggering debouncer
  private readonly debouncer;

  private _queue: AnalytikaEvent[] = []; // list of json events

  private _progressQueue: AnalytikaEvent[] = [];

  private static instance: Analytika | undefined = undefined;

  private readonly _headers: Record<string, string> = {};

  /**
   * Represents analytika instance constructor.
   * @constructor
   * @private
   * @param {Environment} env - environment of sdk qa dev-rh main table , dev/test -> dev/rejected table prod-rh, prod for analytika production main table
   * @param {Record<string,string>} headers - specify other headers to be added to analytika requests
   * @param {number} batchTime - frequency of batch messaging set to 30 seconds by default
   * @param {number} maxBatchSize - maxSize for the queue to have data
   * @param {number} maxWaitBeforeSending - max wait time since sending the first event before flushing events to the backend]
   * @param {number} expirationInDays - maximum period of events to be persisted in local storage before expiration
   * @param {boolean} inCluster - specify if this is in cluster events (Internal to careem network) default: false
   */
  private constructor(
    env: Environment,
    headers: Record<string, string>,
    batchTime: number,
    maxBatchSize: number,
    maxWaitBeforeSending: number,
    expirationInDays: number,
    inCluster: boolean = false
  ) {
    this._batchTime = batchTime;
    this._env = env;
    this._maxBatchSize = maxBatchSize;
    this._maxWaitBeforeSending = maxWaitBeforeSending * 1000;
    this._expirationInDays = expirationInDays;

    this._headers = {
      ...headers,
      "Content-Type": "application/json",
    };

    this.debouncer = debounce(this.flush, this._batchTime * 1000, {
      trailing: true,
      maxWait: this._maxWaitBeforeSending,
      leading: false,
    });

    // setup url
    switch (env) {
      case "qa":
        this._url = inCluster ? this.qaInClusterEndpoint : this.qaEndpoint;
        this._env = "test";
        break;
      default:
        this._url = inCluster ? this.prodInClusterEndpoint : this.prodEndpoint;
        this._env = env;
    }

    this._storageQueueName = `${persistedQueueName}_${this._env}`;
  }

  /**
   * Attach hooks and initalize queue
   * @method
   * @private
   * @returns {void}
   */
  private onReady(): void {
    this._preloadQueueInit();
  }

  /**
   * Represents analytika instance intializer.
   * @method
   * @public
   * @static
   * @param {Environment} env - environment of sdk
   * @param {boolean} inCluster - specify if this is in cluster events (Internal to careem network) default: false
   * @param {Record<string,string>} headers - specify other headers to be added to analytika requests
   * @param {number} batchTime - frequency of batch messaging in seconds set to 30 seconds by default
   * @param {number} maxBatchSize - max length of queue as batch size before force flushing
   * @param {number} maxWaitBeforeSending - max wait time in seconds from triggering the first event before batching and sending events
   * @param {number} expirationInDays - event should be expired after how many days (events not successfully sent are presisted in local storage)
   * @returns {void}
   */
  public static init(
    env: Environment = "prod",
    inCluster: boolean = false,
    headers: Record<string, string> = {},
    batchTime: number = 30,
    maxBatchSize: number = 30,
    maxWaitBeforeSending: number = 60,
    expirationInDays: number = 3
  ): void {
    if (Analytika.instance !== undefined) {
      console.error("Multiple initialization of analytika sdk");
    }

    Analytika.instance = new Analytika(
      env,
      headers,
      batchTime,
      maxBatchSize,
      maxWaitBeforeSending,
      expirationInDays,
      inCluster
    );

    Analytika.instance.onReady();
  }

  /**
   * Represents a singleton accessor.
   * @method
   * @public
   * @static
   * @returns {Analytika} - analytika singelton to be globally used
   */
  public static getInstance(): Analytika {
    if (Analytika.instance === undefined) {
      throw new Error("need to init analytika before fetching instance");
    }

    return Analytika.instance;
  }

  /**
   * Add event to batching queue.
   * @method
   * @private
   * @param {AnalytikaEvent} event - analytika event with the required attributes
   * @returns {void}
   */
  private sendEvent(event: AnalytikaEvent): void {
    if (typeof event !== "object") {
      console.error("invalid event type being sent");
      return;
    }

    event.event_trigger_time = new Date().valueOf();
    this._queue.push(event); // push event to waiting queue
    this._persistQueue();
    this.debouncer(); // call debouncer to run after batch time of firing this event with maxWait == maxWaitBeforeFiring
    if (this._queue.length >= this._maxBatchSize) {
      // if queue has length = maxBatchSize flush queue
      this.flush();
    }
  }

  /**
   * Publish event to analytika.
   * @method
   * @public
   * @static
   * @param {AnalytikaEvent} event - analytika event with the required attributes
   * @returns {void}
   */
  public static publish(event: AnalytikaEvent): void {
    Analytika.getInstance().sendEvent(event);
  }

  /**
   * Flushing batching queue to ingestor
   * @method
   * @public
   * @returns {Promise<void>} - a promise of successfull flush
   */
  public async flush(): Promise<void> {
    if (!Array.isArray(this._queue) || this._queue.length === 0) {
      return Promise.resolve(); // return a resolved promised
    }

    const data = this._generatePostData();
    // copy the new queue array to the progress array
    this._progressQueue = this._queue;
    this._queue = []; // reset the queue

    // send to ingestor the data
    return fetch(this._url, {
      method: "POST",
      headers: this._headers,
      keepalive: true, // request should outlive page closing (only supported by fetch)
      body: JSON.stringify(data),
    })
      .then(() => {
        this._progressQueue = []; // reset progress queue
      }) // reset queue
      .catch(error => {
        console.error(error);
        // assuming queue is the short one
        this._queue = this._progressQueue.concat(this._queue);
        // empty this progress queue
        this._progressQueue = [];
        // retry again in the next debounce
        this.debouncer();
      })
      .finally(() => {
        this._persistQueue();
      });
  }

  /**
   * Generate platform schema version data for the current version
   * @method
   * @private
   * @returns {Record<string, unknown> } - http analytika event body request
   */
  private _generatePlatformKeys(): Record<string, string | number | undefined> {
    const parser = new UAParser(window.navigator.userAgent);
    const browser = parser.getBrowser();
    const os = parser.getOS();

    return {
      env: this._env,
      os_name: os.name,
      browser_name: browser.name,
      platform_schema_version: this._platform_schema_version,
    };
  }

  /**
   * Generate ingestor request data for analytika backend
   * @method
   * @returns {AnalytikaRequest} - request body with the current events
   */
  private _generatePostData(): AnalytikaRequest {
    const platformKeys = this._generatePlatformKeys();

    // events in queue
    return { sessionAttributes: platformKeys, events: this._queue };
  }

  /**
   * Saves current queue to local storage
   * @method
   * @private
   * @returns {void} - request body with the current events
   */
  private _persistQueue(): void {
    localStorage.setItem(this._storageQueueName, JSON.stringify(this._queue));
  }

  /**
   * Preloaded already loaded events
   * @method
   * @private
   * @returns {void} - request body with the current events
   */
  private _preloadQueueInit(): void {
    const items = localStorage.getItem(this._storageQueueName);

    const preloadedQueue = JSON.parse(items || "[]");

    if (!Array.isArray(preloadedQueue)) {
      return;
    }

    const filtered = preloadedQueue.filter(el => {
      if (typeof el !== "object") {
        return false;
      }

      if (!el.event_name || !el.event_version || !el.event_trigger_time) {
        return false;
      }

      if (typeof el.event_trigger_time !== "number") {
        return false;
      }

      const diffTime = Math.abs(new Date().valueOf() - el.event_trigger_time);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays >= this._expirationInDays) {
        return false;
      }

      return true;
    });

    if (filtered.length > 0) {
      this._queue = filtered.concat(this._queue);
      this.flush();
    }
  }
}
